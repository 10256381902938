import {
  TYPE_SYMBOL,
  COMPUTING_CELL,
  SAVING_CELL,
  NEW_CELL,
  OPEN_ARTICLE,
  HIDE_CODE,
  SHOW_CODE,
  DELETE_CELL,
  COMPUTED_CELL,
} from './actionTypes.js'

export const typeSymbol = payload => ({
  type: TYPE_SYMBOL,
  ...payload
})

export const computeCell = payload => ({
  type: COMPUTING_CELL,
  ...payload
})

export const saveCell = payload => ({
  type: SAVING_CELL,
  ...payload
})

export const computedCell = payload => ({
  type: COMPUTED_CELL,
  ...payload
})

export const newCell = payload => ({
  type: NEW_CELL,
  ...payload
})

export const deleteCell = payload => ({
  type: DELETE_CELL,
  ...payload
})

export const hideCode = payload => ({
  type: HIDE_CODE,
  ...payload
})

export const showCode = payload => ({
  type: SHOW_CODE,
  ...payload
})

export const openArticle = payload => ({
  type: OPEN_ARTICLE,
  ...payload
})
