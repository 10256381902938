export const TYPE_SYMBOL = 'TYPE_SYMBOL';
export const FINISHED_TYPING = 'FINISHED_TYPING';
export const COMPUTING_CELL = 'COMPUTING_CELL';
export const COMPUTED_CELL = 'COMPUTED_CELL';
export const SAVING_CELL = 'SAVING_CELL';
export const NEW_CELL = 'NEW_CELL';
export const DELETE_CELL = 'DELETE_CELL';
export const CREATE_CELL = 'CREATE_CELL';
export const SHOW_CODE = 'SHOW_CODE';
export const HIDE_CODE = 'HIDE_CODE';
export const NEW_ARTICLE = 'NEW_ARTICLE';
export const OPEN_ARTICLE = 'OPEN_ARTICLE';
export const CALCULATED_REFS = 'CALCULATED_REFS';
export const AUTOSAVE = 'AUTOSAVE';
