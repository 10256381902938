import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux'

import CodeCell from './CodeCell.js'

import { newCell, deleteCell, hideCode, showCode } from '../redux/actions'

class Cell extends React.Component {
  static propTypes = {
    newCell: PropTypes.func.isRequired,
    deleteCell: PropTypes.func.isRequired,
    hideCode: PropTypes.func.isRequired,
    showCode: PropTypes.func.isRequired,
    cells: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  setActive(key) {
    this.setState({ [key]: true });
  }

  setInactive(key) {
    this.setState({ [key]: false });
  }

  isActive(key) {
    return this.state[key] || false
  }

  onNewCellClick = (key) => {
    this.props.newCell({ key })
  }

  onDeleteClick = (key) => {
    this.props.deleteCell({ key })
  }

  onHideCode = (key) => {
    this.props.hideCode({ key })
  }

  onShowCode = (key) => {
    this.props.showCode({ key })
  }

  render() {
    return (
      this.props.cells.map((object, _key) => {
        let className = 'cell';
        if (this.isActive(object.key)) {
          className += ' active';
        } else {
          className += ' inactive';
        }

        if (object.isRunning) {
          className += ' running';
        } else {
          className += ' computed';
        }

        return <div key={object.key}
          onMouseEnter={() => this.setActive(object.key)}
          onMouseLeave={() => this.setInactive(object.key)}
          className={className}
        >
          <CodeCell cellKey={object.key} object={object} />

          <div className="new-cell">
            <span className="btn" onClick={() => this.onNewCellClick(object.key)}>New cell</span>
            { object.isVisible() === false
              ? <span className="btn" onClick={() => this.onShowCode(object.key)}>Show code</span>
              : object.output !== undefined && <span className="btn" onClick={() => this.onHideCode(object.key)}>Hide code</span>
            }
            <span className="btn" onClick={() => this.onDeleteClick(object.key)}>Delete</span>
            &nbsp;
          </div>
        </div>
      })
    )
  }
}

const mapStateToProps = state => ({
  cells: state.article.cells
})

const mapDispatchToProps = {
  newCell,
  deleteCell,
  showCode,
  hideCode,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cell)
