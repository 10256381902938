import { languagePluginLoader } from './pyodide.js';

let pythonLoading;

// eslint-disable-next-line
(() => {
  // Capture console.log events and broadcast them to the main application
  const consoleDebug = console.debug;
  const consoleLog = console.log;
  const consoleInfo = console.info;
  const consoleWarn = console.warn;
  const consoleError = console.error;

  const handler = (obj, method, ...args) => {
    const payload = {
      method, value: Array.from(args), datetime: Date(),
    }

    obj.apply(console, ...args)

    // broadcast console output to the main application
    parent.postMessage({
      type: 'CONSOLE_EVENT',
      payload,
    }, process.env.REACT_APP_DIGGY_APP_ORIGIN);
  };

  console.debug = (...args) => { handler(consoleDebug, 'debug', args) }
  console.log = (...args) => { handler(consoleLog, 'log', args) }
  console.info = (...args) => { handler(consoleInfo, 'info', args) }
  console.warn = (...args) => { handler(consoleWarn, 'warn', args) }
  console.error = (...args) => { handler(consoleError, 'error', args) }
})();

function trim(string) { return string.replace(/\n$/, '') }

// eslint-disable-next-line
async function loadPythonPackages() {
  // eslint-disable-next-line
  await languagePluginLoader;

  let code = `
    from markdown import markdown as md
    from pyflakes import api as pyflakes_api

    import io

    class CallbackBuffer(object):
        """
        Internal StdStream buffer that trigger flush callback.
        """
        def __init__(self, flush_callback):
            self._flush_callback = flush_callback
            self.closed = False
        def writable(self): return True
        def seekable(self): return False
        def write(self, data):
            self._flush_callback(data.tobytes().decode())
            return len(data)

    class StdStream(io.TextIOWrapper):
        """
        Custom std stream to retdirect sys.stdou/stderr to terminal.
        """
        def __init__(self, flush_callback):
            buffer = io.BufferedWriter(CallbackBuffer(flush_callback))
            super().__init__(buffer, line_buffering=True)

  `
  pythonLoading = pyodide.runPythonAsync(code)
}

loadPythonPackages().then(() => {
  parent.postMessage({
    type: 'RUNTIME_STATE',
    state: 'READY',
  }, process.env.REACT_APP_DIGGY_APP_ORIGIN);
});

const onmessage = async(event) => {
  // eslint-disable-next-line
  await languagePluginLoader;

  // since loading package is asynchronous, we need to make sure loading is done:
  await pythonLoading

  // Don't bother yet with this line, suppose our API is built in such a way:
  const { python, ...context } = event;

  self.term = {}
  self.term.log = []
  self.term.stdout = {
    write: function(string) {
      const object = trim(string)
      const payload = {
        method: 'log', value: object, datetime: Date(),
      }
      self.term.log.push(payload)
    }
  }
  self.term.stderr = {
    write: function(string) {
      const object = trim(string)
      const payload = {
        method: 'error', value: object, datetime: Date(),
      }
      self.term.log.push(payload)
    }
  }

  const code = `
    from js import term

    sys.stdout = StdStream(term.stdout.write)
    sys.stderr = StdStream(term.stderr.write)
  `
  await pyodide.runPythonAsync(code)

  // The worker copies the context in its own "memory" (an object mapping name to values)
  for (const key of Object.keys(context)){
    self[key] = context[key];
  }

  // Now is the easy part, the one that is similar to working in the main thread:
  try {
    let results = await pyodide.runPythonAsync(python)

    // we cannot pass results as is, i.e. it could be a proxy object
    // that we won't be able to serialize properly, e.g. function
    // calls; however, we're only interested in a small subset of
    // attributes which we extract here

    let _repr_html_

    if (results === undefined) {
      results = 'undefined'
    }

    if (results._repr_html_ !== undefined) {
      _repr_html_ = results._repr_html_()
    } else if (results.outerHTML !== undefined) {
      _repr_html_ = results.outerHTML
    } else {
      // fallback if we don't know much about the object
      _repr_html_ = results
    }

    return {
      results: _repr_html_,
      stdout: self.term.log
    }
  } catch (error) {
    return {
      error: error.message,
      stdout: self.term.log
    }
  }
}

export async function run(script, context, onSuccess, onError) {
  parent.postMessage({
    type: 'RUNTIME_STATE',
    state: 'LOADING',
  }, process.env.REACT_APP_DIGGY_APP_ORIGIN);

  const e = await onmessage({
    ...context,
    python: script,
  })

  parent.postMessage({
    type: 'RUNTIME_STATE',
    state: 'READY',
  }, process.env.REACT_APP_DIGGY_APP_ORIGIN);

  if (e.results) {
    onSuccess(e)
  } else {
    onError(e)
  }
}

// Transform the run (callback) form to a more modern async form.
// This is what allows to write:
//    const {results, error} = await runPythonAsync(script, context);
// Instead of:
//    run(script, context, successCallback, errorCallback);
export function runPythonAsync(script, context) {
  return new Promise(function(onSuccess, onError) {
    run(script, context, onSuccess, onError)
  })
}
