import { Cell } from '../../models/cell.js';

import {
  TYPE_SYMBOL,
  FINISHED_TYPING,
  COMPUTING_CELL,
  COMPUTED_CELL,
  CREATE_CELL,
  DELETE_CELL,
  SHOW_CODE,
  HIDE_CODE,
  NEW_ARTICLE,
  OPEN_ARTICLE,
  CALCULATED_REFS,
} from '../actionTypes.js';

const initialState = {
  totalCells: 0,
  cells: []
}

export default function(state = initialState, action) {
  switch (action.type) {
  case TYPE_SYMBOL: {
    return {
      ...state,
      cells: state.cells.map((el) => {
        if (el.key !== action.key) {
          return el
        }

        const attrs = { ...el.options, isDirty: true, isComputed: false, output: el.output }
        const cell = new Cell(el.key, action.value, attrs)
        return cell
      })
    }
  }
  case FINISHED_TYPING: {
    return state;
  }
  case COMPUTING_CELL:  {
    return {
      ...state,
      cells: state.cells.map((el) => {
        if (el.key !== action.key) {
          return el
        }

        // don't flash if a cell has been computed already
        const value = el.output || 'Loading...'
        const attrs = { ...el.options, isRunning: true, output: value }
        const cell = new Cell(el.key, el.value, attrs)
        return cell
      })
    }
  }
  case COMPUTED_CELL: {
    return {
      ...state,
      cells: state.cells.map((el) => {
        if (el.key !== action.key) {
          return el
        }

        let attrs
        if (action.silent)
          attrs = {
            ...el.options,
            isRunning: false,
            isComputed: true
          }
        else {
          attrs = {
            ...el.options,
            isRunning: false,
            output: action.value,
            stdout: action.stdout,
            isComputed: true
          }
        }
        const cell = new Cell(el.key, el.value, attrs)
        return cell
      })
    }
  }
  case CREATE_CELL: {
    let cellIndex = null
    state.cells.forEach((el, index) => {
      if (el.key === action.key) {
        cellIndex = index;
        return false;
      }
    })

    const newCell = new Cell(`cell-${state.totalCells + 1}`, '')

    return {
      ...state,
      totalCells: state.totalCells + 1,
      cells: [
        ...state.cells.slice(0, cellIndex + 1),
        newCell,
        ...state.cells.slice(cellIndex + 1),
      ]
    }
  }
  case DELETE_CELL: {
    let cellIndex = null
    state.cells.forEach((el, index) => {
      if (el.key === action.key) {
        cellIndex = index;
        return false;
      }
    })

    return {
      ...state,
      totalCells: state.totalCells + 1,
      cells: [
        ...state.cells.slice(0, cellIndex),
        ...state.cells.slice(cellIndex + 1),
      ]
    }
  }
  case SHOW_CODE: {
    return {
      ...state,
      cells: state.cells.map((el) => {
        if (el.key !== action.key) {
          return el
        }

        const attrs = { ...el.options, isVisible: true }
        const cell = new Cell(el.key, el.value, attrs)
        return cell
      })
    }
  }
  case HIDE_CODE: {
    return {
      ...state,
      cells: state.cells.map((el) => {
        if (el.key !== action.key) {
          return el
        }

        const attrs = { ...el.options, isVisible: false }
        const cell = new Cell(el.key, el.value, attrs)
        return cell
      })
    }
  }
  case NEW_ARTICLE: {
    return {
      ...state,
      totalCells: 0,
      cells: []
    }
  }
  case OPEN_ARTICLE: {
    let totalCells = 0
    action.cells.forEach(el => {
      let val = parseInt(el.key.split('-')[1])
      if (val > totalCells) {
        totalCells = val
      }
    })
    totalCells += 1
    return {
      ...state,
      totalCells,
      cells: action.cells.map(el => new Cell(el.key, el.value, el.options))
    }
  }
  case CALCULATED_REFS: {
    return {
      ...state,
      cells: state.cells.map((el) => {
        let actionCell
        action.cells.forEach(cell => {
          if (cell.key === el.key) {
            actionCell = cell
            return
          }
        })

        let attrs
        if (actionCell !== undefined) {
          attrs = { ...el.options, dependsOn: actionCell.dependsOn, declares: actionCell.declares, isDirty: false }
        } else {
          // A user added a new cell while CALCULATED_REFS was running
          attrs = { ...el.options }
        }

        const cell = new Cell(el.key, el.value, attrs)
        return cell
      })
    }
  }
  default: {
    return state
  }
  }
}
