import { runPythonAsync } from '../../py-worker';
import { ofType, combineEpics } from 'redux-observable';
import { catchError, debounce, map, switchMap, mergeMap, concatMap } from 'rxjs/operators';
import { from, of, interval } from 'rxjs';
import {
  TYPE_SYMBOL,
  FINISHED_TYPING,
  COMPUTING_CELL,
  COMPUTED_CELL,
  SAVING_CELL,
  NEW_CELL,
  CREATE_CELL,
  NEW_ARTICLE,
  OPEN_ARTICLE,
  CALCULATED_REFS,
  AUTOSAVE,
} from '../actionTypes.js'
import { topologicalSort, selectGraph, graphRefs, graphDeps, calculateGraph, motherVertices } from '../../graph.js';

const autoSaveHandler = (cells) => {
  const origin = process.env.REACT_APP_DIGGY_APP_ORIGIN;
  parent.postMessage({
    type: AUTOSAVE,
    cells: cells.map((c) => ({
      key: c.key,
      output: c.output,
      value: c.value,
      options: {
        isVisible: c.options.isVisible
      }
    })),
  }, origin)
}

const autoSave = (action$, state$) => action$.pipe(
  ofType(OPEN_ARTICLE),
  switchMap(() => interval(30000).pipe(
    // autosave every 10 seconds
    map((_time) => {
      autoSaveHandler(state$.value.article.cells)

      return { type: AUTOSAVE }
    })
  ))
)

const typingSymbol = (action$, state$) => action$.pipe(
  ofType(TYPE_SYMBOL),
  debounce(() => interval(1000)),
  map(msg => {
    autoSaveHandler(state$.value.article.cells)

    return { type: FINISHED_TYPING, key: msg.key }
  })
)

const openArticle = (action$, state$) => action$.pipe(
  ofType(OPEN_ARTICLE),
  // TODO: this is a workaround to create all cell object, could be
  // refactored better by utilizing NEW_ARTICLE
  debounce(() => interval(50)),
  switchMap(async _msg => {
    const cells = await calculateGraph(state$.value.article.cells)
    const graph = graphDeps(cells)
    const topological = topologicalSort(graph)
    const dirty = motherVertices(graph, topological)

    return { type: CALCULATED_REFS, cells, dirty }
  })
)

const savingCell = (action$, state$) => action$.pipe(
  ofType(SAVING_CELL),
  switchMap(async msg => {
    const cells = await calculateGraph(state$.value.article.cells)
    const topological = selectGraph(graphRefs(cells), msg.key).reverse()
    const dirty = motherVertices(graphDeps(cells), topological)

    return { type: CALCULATED_REFS, cells, dirty, cell: msg.key }
  })
)

const calculatedRefs = (action$, state$) => action$.pipe(
  ofType(CALCULATED_REFS),
  concatMap((msg) => {
    let messages = msg.dirty.map(key => {
      const calculateCell = state$.value.article.cells.find(el => el.key === key)

      return { type: COMPUTING_CELL, key: calculateCell.key, value: calculateCell.value, cell: msg.cell }
    })
    return messages.filter(el => el)
  })
)

const newCell = action$ => action$.pipe(
  ofType(NEW_CELL),
  map(msg => ({ type: CREATE_CELL, key: msg.key }))
)

const newArticle = action$ => action$.pipe(
  ofType(NEW_ARTICLE),
  // TODO: this is a workaround to give a page component some time to
  // clean up cells
  debounce(() => interval(50)),
  map(msg => ({ ...msg, type: OPEN_ARTICLE }))
)

const postComputeCell = action$ => action$.pipe(
  ofType(COMPUTED_CELL),
  mergeMap(msg => {
    if (msg.data !== undefined) {
      let el = document.getElementById(msg.key).parentNode.getElementsByTagName('canvas')
      if (el.length > 0) {
        el = el[0]
      }
      const context = el.getContext('2d');
      context.putImageData(msg.data, 0, 0)
    }

    return []
  })
)

const computedCell = (action$, state$) => action$.pipe(
  ofType(COMPUTED_CELL),
  switchMap(async msg => {
    const cells = await calculateGraph(state$.value.article.cells)
    const graph = graphDeps(cells)
    const topological = selectGraph(graph, msg.key).slice(1)
    const dirty = motherVertices(graph, topological)

    return { type: CALCULATED_REFS, cells, dirty: dirty, cell: dirty[0] }
  })
)

const computeCell = (action$, state$) => action$.pipe(
  ofType(COMPUTING_CELL),
  concatMap(msg => {
    const key = msg.key
    const code = msg.value

    const calculateCell = state$.value.article.cells.find(el => el.key === key)
    if (calculateCell.isComputed && !calculateCell.isDirty && calculateCell.key !== msg.cell) {
      const { output, data } = convertToElement(calculateCell.output)
      const payload = { type: COMPUTED_CELL, value: output, data, key, cell: msg.cell, silent: true }
      return of(payload)
    }

    return from(runPythonAsync(code, {}))
      .pipe(
        switchMap(results => {
          const value = results.results || results.error
          const stdout = results.stdout
          const { output, data } = convertToElement(value)
          const payload = { type: COMPUTED_CELL, value: output, data, stdout, key, cell: msg.cell }
          return of(payload)
        }),
        catchError(err => {
          const message = err.message || err.error
          const stdout = err.stdout
          let value
          if (message !== undefined) {
            value = message.replace(/\\n/g, '\r\n')
          } else {
            value = 'Unknown error'
          }
          const payload = { type: COMPUTED_CELL, value: String(value), stdout, key }
          return of(payload)
        })
      )
  })
)


const convertToElement = value => {
  // if it's an image
  let frag
  let data
  let element
  let output
  if (value instanceof ImageData) {
    // insert canvas first, and then put image data
    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', value.width)
    canvas.setAttribute('height', value.height)

    frag = document.createRange().createContextualFragment(canvas.outerHTML);
    element = document.createElement('div')
    element.appendChild(frag)
    output = element.outerHTML
    data = value
  } else if (typeof value === 'string' || typeof value === 'number') {
    // could be an HTML object, try to convert
    frag = document.createRange().createContextualFragment(value);
    element = document.createElement('div')
    element.appendChild(frag)
    output = element.outerHTML
  } else {
    // Javascript object, render as a tree
    output = value
  }

  return { output, data }
}

const epics = [
  typingSymbol,
  computeCell,
  computedCell,
  postComputeCell,
  newCell,
  newArticle,
  openArticle,
  calculatedRefs,
  savingCell,
  autoSave,
];

export const rootEpic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );
