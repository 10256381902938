import { createStore, compose, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable';

import { openArticle } from './actions.js';
import { NEW_ARTICLE } from './actionTypes.js'
import { rootReducer } from './reducers';
import { rootEpic } from './epics';

const epicMiddleware = createEpicMiddleware();

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

/* eslint-disable no-underscore-dangle */
const store = createStore(
  rootReducer, /* preloadedState, */
  composeEnhancers(
    applyMiddleware(epicMiddleware)
  ));
/* eslint-enable */

export default store;


// create an Observer instance
const resizeObserver = new ResizeObserver(e => {
  const el = e[0]
  const origin = process.env.REACT_APP_DIGGY_APP_ORIGIN;

  // send a message to the application
  parent.postMessage({
    type: 'RESIZE_ARTICLE',
    height: el.target.scrollHeight,
    width: el.target.scrollWidth,
  }, origin)
})

// start observing a DOM node, this could be incompatible with Android FireFox
resizeObserver.observe(document.body)

var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
var eventer = window[eventMethod];
var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

// Listen to a message from the parent
eventer(messageEvent, (e) => {
  const origin = process.env.REACT_APP_DIGGY_APP_ORIGIN
  if (!e.origin.startsWith(origin))
    return;

  const key = e.message ? 'message' : 'data';
  const data = e[key];
  switch (data.type) {
  case NEW_ARTICLE: {
    const payload = {
      cells: data.cells,
    }
    store.dispatch(openArticle(payload))
    break;
  }
  }
}, false);

epicMiddleware.run(rootEpic);

parent.postMessage({
  type: 'RUNTIME_STATE',
  state: 'INITIALIZED',
}, process.env.REACT_APP_DIGGY_APP_ORIGIN);
