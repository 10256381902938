import { runPythonAsync } from './py-worker';

const pythonRefs = (key, code) => (`
import base64

class LoggingReporter(object):
    """
    Implementation of Reporter that just appends any error to a list.
    """
    def __init__(self, variables):
        self.varlog = variables
    def flake(self, message):
        ...
    def variables(self, message):
        self.varlog = message
    def get_variables(self):
        return self.varlog
    def unexpectedError(self, filename, message):
        ...
    def syntaxError(self, filename, msg, lineno, offset, line):
        ...

reporter = LoggingReporter({})
code = base64.b64decode('${code}').decode('utf-8')
pyflakes_api.check(code, '/tmp-${key}.py', reporter)
variables = reporter.get_variables()
{ 'key': '${key}', 'dependsOn': variables.get('deps', []), 'declares': variables.get('refs', []) }
`)


function topologicalSortHelper(node, visited, temp, graph, result) {
  temp[node] = true;
  var neighbors = graph[node];
  for (var i = 0; i < neighbors.length; i += 1) {
    var n = neighbors[i];
    if (temp[n]) {
      throw new Error('The graph is not a DAG');
    }
    if (!visited[n]) {
      topologicalSortHelper(n, visited, temp, graph, result);
    }
  }
  temp[node] = false;
  visited[node] = true;
  result.push(node);
}

export const topologicalSort = (graph) => {
  var result = [];
  var visited = [];
  var temp = [];
  for (var node in graph) {
    if (!visited[node] && !temp[node]) {
      topologicalSortHelper(node, visited, temp, graph, result);
    }
  }
  return result.reverse();
}

/**
 * Topological sort algorithm of a directed acyclic graph.<br><br> //
 * Time complexity: O(|E| + |V|) where E is a number of edges //
 * and |V| is the number of nodes. //
 * //
 * @public //
 * @module graphs/others/topological-sort //
 * @param {Array} graph Adjacency list, which represents the graph. //
 * @returns {Array} Ordered vertices. //
 * //
 * @example //
 * var topsort = //
 *  require('path-to-algorithms/src/graphs/' + //
 * 'others/topological-sort').topologicalSort; //
 * var graph = { //
 *     v1: ['v2', 'v5'], //
 *     v2: [], //
 *     v3: ['v1', 'v2', 'v4', 'v5'], //
 *     v4: [], //
 *     v5: [] //
 * }; //
 * var vertices = topsort(graph); // ['v3', 'v4', 'v1', 'v5', 'v2'] //
 */
export const selectGraph = (graph, node) => {
  var visited = [];
  var result = [];
  var temp = [];
  topologicalSortHelper(node, visited, temp, graph, result);
  return result.reverse();
}

export const graphDeps = (cells) => {
  let graph = {}
  cells.forEach(cell => { graph[cell.key] = [] })
  cells.forEach(cell1 => {
    cell1.dependsOn.forEach(dep => {
      cells.forEach(cell2 => {
        if (cell1.key != cell2.key) {
          if (cell2.declares.indexOf(dep) >= 0 && graph[cell2.key].indexOf(cell1.key) == -1) {
            graph[cell2.key].push(cell1.key)
          }
        }
      })
    })
  })
  return graph
}

export const graphRefs = (cells) => {
  let graph = {}
  cells.forEach(cell => { graph[cell.key] = [] })
  cells.forEach(cell1 => {
    cell1.declares.forEach(ref => {
      cells.forEach(cell2 => {
        if (cell1.key != cell2.key) {
          if (cell2.dependsOn.indexOf(ref) >= 0 && graph[cell2.key].indexOf(cell1.key) == -1) {
            graph[cell2.key].push(cell1.key)
          }
        }
      })
    })
  })
  return graph
}

export const calculateGraph = async allCells => {
  let cells = []
  for (const cell of allCells) {
    let results = { ...cell }
    if (cell.isDirty) {
      const code = pythonRefs(cell.key, btoa(unescape(encodeURIComponent(cell.value))))
      const stdout = await runPythonAsync(code)
      results = { ...results, ...stdout.results }
    }
    cells.push(results)
  }
  return cells
}


function hasPath(graph, current, goal) {
  var stack = [];
  var visited = [];
  var node;
  stack.push(current);
  visited[current] = true;
  while (stack.length) {
    node = stack.pop();
    if (node === undefined) {
      continue
    }
    if (node === goal) {
      return true;
    }
    var neighbors = graph[node];
    for (var i = 0; i < neighbors.length; i += 1) {
      var n = neighbors[i];

      if (!visited[n]) {
        stack.push(n);
        visited[n] = true;
      }
    }
  }
  return false;
}

/**
 * Depth-First graph searching algorithm.
 * Returns whether there's a path between two nodes in a graph.<br><br>
 * Time complexity: O(|V|^2).
 *
 * @module graphs/searching/dfs
 * @public
 * @param {Array} graph Adjacency matrix, which represents the graph.
 * @param {Number} start Start node.
 * @param {Number} goal Target node.
 * @return {Boolean} Returns true if path between two nodes exists.
 *
 * @example
 * var dfs = require('../src/graphs/searching/dfs').dfs;
 * var graph = { //
 *     v1: ['v2', 'v5'], //
 *     v2: [], //
 *     v3: ['v1', 'v2', 'v4', 'v5'], //
 *     v4: [], //
 *     v5: [] //
 * }; //
 * var vertices = dfs(graph, 'v1', 'v5'); // true
 */
export const dfs = (graph, start, goal) => {
  return hasPath(graph, start, goal);
}

export const motherVertices = (graph, topological) => {
  if (topological.length === 0) {
    return []
  }

  let current = 0
  let vertices = [topological[current]]
  for (let i = 1; i < topological.length; i++) {
    if (!dfs(graph, vertices[current], topological[i])) {
      vertices.push(topological[i])
      current = i
    }
  }
  return vertices
}
