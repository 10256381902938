import React from 'react';
import PropTypes from 'prop-types';
import JSONTree from 'react-json-tree'

import { connect } from 'react-redux'
import { typeSymbol, saveCell } from '../redux/actions'

// Core
import { EditorView } from '@codemirror/view'
import { EditorState, Prec } from '@codemirror/state'

// Basic setup
import { keymap, highlightSpecialChars, drawSelection } from '@codemirror/view'
import { indentOnInput } from '@codemirror/language'
import { history, historyKeymap } from '@codemirror/history'
import { foldKeymap } from '@codemirror/fold'
import { defaultKeymap } from '@codemirror/commands'
import { bracketMatching } from '@codemirror/matchbrackets'
import { closeBrackets, closeBracketsKeymap } from '@codemirror/closebrackets'
import { autocompletion, completionKeymap } from '@codemirror/autocomplete'
import { commentKeymap } from '@codemirror/comment'
import { highlightSelectionMatches } from '@codemirror/search'
import { defaultHighlightStyle } from '@codemirror/highlight'

// Python specific addons
import { python } from '@codemirror/lang-python'

const theme = {
  scheme: 'solarized',
  author: 'ethan schoonover (http://ethanschoonover.com/solarized)',
  base00: '#ffffff', // BACKGROUND_COLOR
  base01: '#073642', // ???
  base02: '#586e75', // ???
  base03: '#657b83', // ITEM_STRING_EXPANDED_COLOR
  base04: '#839496', // ???
  base05: '#93a1a1', // ???
  base06: '#eee8d5', // ???
  base07: '#000000', // TEXT_COLOR
  base08: '#000000', // NULL_COLOR, UNDEFINED_COLOR, FUNCTION_COLOR, SYMBOL_COLOR
  base09: '#000000', // NUMBER_COLOR, BOOLEAN_COLOR
  base0A: '#b58900', // ???
  base0B: '#aa1111', // STRING_COLOR, DATE_COLOR, ITEM_STRING_COLOR
  base0C: '#2aa198', // ???
  base0D: '#268bd2', // LABEL_COLOR, ARROW_COLOR
  base0E: '#6c71c4', // ???
  base0F: '#d33682', // ???
}

class CodeCell extends React.Component {
  static propTypes = {
    typeSymbol: PropTypes.func.isRequired,
    saveCell: PropTypes.func.isRequired,
    object: PropTypes.object.isRequired,
    cellKey: PropTypes.string.isRequired,
  }

  basicSetup = [
    highlightSpecialChars(),
    history(),
    drawSelection(),
    indentOnInput(),
    Prec.fallback(defaultHighlightStyle),
    bracketMatching(),
    closeBrackets(),
    autocompletion(),
    highlightSelectionMatches(),
    keymap.of([
      ...closeBracketsKeymap,
      ...defaultKeymap,
      ...historyKeymap,
      ...foldKeymap,
      ...commentKeymap,
      ...completionKeymap,
      { key: 'Cmd-Enter', run: () => { this.onRunWidget() }},
    ])
  ]

  constructor(props) {
    super(props)

    this.refEditor = React.createRef()
  }

  componentDidMount() {
    this.editorState = EditorState.create({ doc: this.props.object.value, extensions: [
      this.basicSetup,
      python(),
      this.onEditorChange,
    ]})
    this.editorView = new EditorView({
      state: this.editorState,
      editable: true,
      lineWrapping: true,
      parent: this.refEditor.current,
    })
    this.editorView.focus()
  }

  onEditorChange = EditorView.updateListener.of((event) => {
    if (!event.docChanged || (event.state.doc.toJSON().join('\n') === this.props.object.value)) {
      return
    }

    const text = this.editorView.viewState.state.doc.toJSON() || []
    const payload = {
      value: text.join('\n'),
      key: this.props.cellKey,
    }
    this.props.typeSymbol(payload)
  })

  onRunWidget = () => {
    const text = this.editorView.viewState.state.doc.toJSON() || []
    const payload = {
      key: this.props.cellKey,
      value: text.join('\n'),
    }
    this.props.saveCell(payload)
  }

  render() {
    return (
      <div>
        <div className="runWidget" onClick={() => this.onRunWidget() }><svg width="16" height="16" className="db" strokeLinejoin="round" fill="none"><path d="M11.7206 6.94335C12.2406 7.34365 12.2406 8.12786 11.7206 8.52816L5.60999 13.2321C4.95242 13.7383 4 13.2696 4 12.4397L4 3.03178C4 2.20194 4.95243 1.73318 5.60999 2.23937L11.7206 6.94335Z" stroke="currentColor" strokeWidth="1.6"></path></svg></div>
        <div id={this.props.cellKey} ref={this.refEditor} value={this.props.object.value}
          className={this.props.object.isVisible() === false ? 'hidden' : 'visible'}
        ></div>
        <div>
          {this.props.object.stdout.map((el, index) => <div key={index} dangerouslySetInnerHTML={{ __html: `<pre>${el.value}</pre>` }}></div>)}
        </div>
        <div className="outputcell">
          {typeof this.props.object.output === 'string' &&
           <div dangerouslySetInnerHTML={{ __html: this.props.object.output }}></div>}
          {typeof this.props.object.output === 'object' &&
           <JSONTree data={this.props.object.output} theme={theme} invertTheme={false} hideRoot={true}/>}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  typeSymbol,
  saveCell,
}

export default connect(
  null,
  mapDispatchToProps
)(CodeCell)
