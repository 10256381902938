export class Cell {
  constructor(key, value, options) {
    this.key = key
    this.value = value

    this.options = options || {}

    this.output = this.options.output
    this.stdout = this.options.stdout || []
    this.isDirty = this.options.isDirty === false ? false : true
    this.isComputed = this.options.isComputed === true
    this.isRunning = this.options.isRunning === true
    this._isVisible = this.options.isVisible === false ? false : true

    this.declares = this.options.declares
    this.dependsOn = this.options.dependsOn
  }

  isVisible() {
    return this._isVisible === true || this.isComputed === false
  }
}
