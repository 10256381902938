import React from 'react';
import PropTypes from 'prop-types';
import { cx, css } from '@emotion/css';

import Cell from './components/Cell.js';

const Wrapper = ({ className, ...props }) => (
  <div
    {...props}
    className={cx(
      className,
      css`
        height: 100%;
        margin: 0 auto;
        padding: 5px 20px 20px;
      `,
      'cells'
    )}
  />
)
Wrapper.propTypes = {
  className: PropTypes.string.isRequired,
}

const Content = props => (
  <Wrapper
    {...props}
    className={css`
      background: #fff;
    `}
  />
)

function App() {
  return (
    <Content>
      <Cell />
    </Content>
  );
}

export default App;
